<template>
  <div>
    <header-title :title="title"></header-title>
    <rs-form :model="formData" :show-message="false" label-width="156px" size="small" ref="formData" class="form-70 commom-form">
      <rs-row>
        <rs-col :span="12">
          <rs-form-item label="资源编码" prop="resourceCode" :rules="[
                            { required: true, message: '请输入资源编码', trigger: 'blur' },
                            { max:30, message: '最大长度为30', trigger: 'blur' },
                        ]">
            <rs-input type="text" v-model="formData.resourceCode" :disabled="type != 0" autocomplete="off"></rs-input>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="资源名称" prop="resourceName" :rules="[
                            { required: true, message: '请输入资源名称', trigger: 'blur' },
                            { max:50, message: '最大长度为50', trigger: 'blur' },
                        ]">
            <rs-input type="text" v-model="formData.resourceName" autocomplete="off"></rs-input>
          </rs-form-item>
        </rs-col>
      </rs-row>
      <rs-row>
        <rs-col :span="12">
          <rs-form-item label="地址" prop="resourceUrl" :rules="[
                        { max:1000, message: '最大长度为1000', trigger: 'blur' },
                        ]">
            <rs-input type="text" v-model="formData.resourceUrl" autocomplete="off"></rs-input>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="资源类型" prop="resourceType" :rules="[
                            { required: true, message: '请选择资源类型', trigger: 'blur' },
                        ]">
            <rs-select class="select_width" v-model="formData.resourceType" placeholder="请选择">
              <rs-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </rs-option>
            </rs-select>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="上级资源" prop="parentResourceId" :rules="[
                        { required: true, message: '请选择上级资源', trigger: 'blur' },
                        ]">
            <rs-select class="select_width" v-model="formData.parentResourceId" placeholder="请选择" @change="handleChange">
              <rs-option v-for="item in parentOptions" :key="item.value" :label="item.label" :value="item.value">
              </rs-option>
            </rs-select>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="一级菜单图标" v-if="formData.resourceType === 1">
            <i class="font_family" v-html="formData.iconCode"></i>
            <a class="main_font marginLeft15" @click="showIcon" v-if="formData.iconCode">更改</a>
            <a class="main_font" @click="showIcon" v-else>选择</a>
          </rs-form-item>
        </rs-col>
      </rs-row>
    </rs-form>
    <div class="edit-form button-box" style="text-align: right">
      <rs-button size="small" @click="jumpBack(false)">取消</rs-button>
      <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Http from '@/api/api'
import orgSelect from '@/components/orgSelect'
import headerTitle from '@/components/header'
import { mapGetters } from 'vuex'
export default {
  components: {
    orgSelect,
    headerTitle
  },
  computed: { ...mapGetters(['resourceInfo']) },
  data() {
    return {
      parentOptions: [],
      loading: false,
      iconDrawer: false,
      options: [{ value: 3, label: '按钮' }, { value: 1, label: '菜单' }, { value: 2, label: '接口' }, { value: 4, label: '其他' }],
      title: '',
      resourceCode: '',
      formData: {
        resourceType: 1,
        resourceName: '',
        resourceCode: '',
        iconCode: '',
        resourceUrl: ''
      },
      saveLoading: false,
      type: '',
      iconArray: ['&#xe669;', '&#xe668;', '&#xe667;', '&#xe664;', '&#xe663;', '&#xe785;', '&#xe661;', '&#xe660;', '&#xe65f;', '&#xe65e;', '&#xe65d;', '&#xe65c;', '&#xe65a;', '&#xe657;', '&#xe656;', '&#xe655;', '&#xe654;', '&#xe653;', '&#xe652;',
        '&#xe651;', '&#xe650;', '&#xe64f;', '&#xe64e;', '&#xe64d;', '&#xe64c;', '&#xe64b;', '&#xe60f;', '&#xe60e;', '&#xe60c;', '&#xe60a;', '&#xe609;', '&#xe608;', '&#xe607;', '&#xe606;'],
    }
  },
  async created() {
    console.log(this.resourceInfo, 'resourceInfo')
    this.title = '新建资源'
    this.resourceCode = this.$route.query.resourceCode
    this.formData.parentResourceId = 1 * this.resourceCode
    this.type = this.$route.query.type
    this.getList()
    if (this.$route.query.id) {
      this.title = '编辑资源'
      this.userId = this.$route.query.id
      await this.handleDetail()
    }
  },
  methods: {
    handleChange(val){
      let selectInfo = this.parentOptions.find(item=>{
       return item.value == val
      })
      if(val){
        this.$set(this.formData,'parentResourceCode',selectInfo.resourceCode)
      }else{
        this.$set(this.formData,'parentResourceCode','')
      }
    },
    showIcon() {
      top.$dialogManage({
        width: '662px',
        title: '图标库',
        formData: this.formData,
        type: 'IconDrawer',
        className: 'form-dialog add-form-dialog',
        confirmFlag: false
      }).then(data => {
        this.submitIcon(data)
      })
    },
    submitIcon(data) {
      this.formData.iconCode = data.iconCode
      console.log(this.formData, 'this.formData')
    },
    validate(cb) {
      this.$refs['form'].validate(cb);
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    getList() {
      Http.getAllResourceList('', 'resourceTypes=1&resourceTypes=2&resourceTypes=4').then((res) => {
        this.parentOptions = res.data.data.map(item => {
          return { value: item.id, label: item.resourceName ,resourceCode:item.resourceCode};
        })
        this.handleChange(this.formData.parentResourceId)
      })
    
    },
    // 获取详情
    async handleDetail() {
      if (this.resourceInfo) this.formData = this.resourceInfo
    },
    // 弹框确认新增
    save() {
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          if (this.formData.id) {
            this.editResource()
          } else {
            this.addResource()
          }
        }
      });
    },
    editResource() {
      Http.updateResource(this.formData).then(res => {
        this.saveLoading = false
        this.$message({
          message: '操作成功！',
          type: 'success'
        });
        this.jumpBack()
      }).catch(err => {
        this.saveLoading = false
      })
    },
    addResource() {
      Http.addResource(this.formData).then(res => {
        this.saveLoading = false
        this.$message({
          message: '操作成功！',
          type: 'success'
        });
        this.jumpBack()
      }).catch(err => {
        this.saveLoading = false
      })
    },
    jumpBack() {
      this.$router.back()
    },
  }
}

</script>
<style lang="less" scoped>
.demo-ruleForm {
  width: 400px;
}
.main_font {
  width: 88px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #0091ff;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  text-align: center;
}
.marginLeft15 {
  display: inline-block;
}
</style>