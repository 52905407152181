var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header-title", { attrs: { title: _vm.title } }),
      _c(
        "rs-form",
        {
          ref: "formData",
          staticClass: "form-70 commom-form",
          attrs: {
            model: _vm.formData,
            "show-message": false,
            "label-width": "156px",
            size: "small",
          },
        },
        [
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    {
                      attrs: {
                        label: "资源编码",
                        prop: "resourceCode",
                        rules: [
                          {
                            required: true,
                            message: "请输入资源编码",
                            trigger: "blur",
                          },
                          { max: 30, message: "最大长度为30", trigger: "blur" },
                        ],
                      },
                    },
                    [
                      _c("rs-input", {
                        attrs: {
                          type: "text",
                          disabled: _vm.type != 0,
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.formData.resourceCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "resourceCode", $$v)
                          },
                          expression: "formData.resourceCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    {
                      attrs: {
                        label: "资源名称",
                        prop: "resourceName",
                        rules: [
                          {
                            required: true,
                            message: "请输入资源名称",
                            trigger: "blur",
                          },
                          { max: 50, message: "最大长度为50", trigger: "blur" },
                        ],
                      },
                    },
                    [
                      _c("rs-input", {
                        attrs: { type: "text", autocomplete: "off" },
                        model: {
                          value: _vm.formData.resourceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "resourceName", $$v)
                          },
                          expression: "formData.resourceName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    {
                      attrs: {
                        label: "地址",
                        prop: "resourceUrl",
                        rules: [
                          {
                            max: 1000,
                            message: "最大长度为1000",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("rs-input", {
                        attrs: { type: "text", autocomplete: "off" },
                        model: {
                          value: _vm.formData.resourceUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "resourceUrl", $$v)
                          },
                          expression: "formData.resourceUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    {
                      attrs: {
                        label: "资源类型",
                        prop: "resourceType",
                        rules: [
                          {
                            required: true,
                            message: "请选择资源类型",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "rs-select",
                        {
                          staticClass: "select_width",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formData.resourceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "resourceType", $$v)
                            },
                            expression: "formData.resourceType",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("rs-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    {
                      attrs: {
                        label: "上级资源",
                        prop: "parentResourceId",
                        rules: [
                          {
                            required: true,
                            message: "请选择上级资源",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "rs-select",
                        {
                          staticClass: "select_width",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.formData.parentResourceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "parentResourceId", $$v)
                            },
                            expression: "formData.parentResourceId",
                          },
                        },
                        _vm._l(_vm.parentOptions, function (item) {
                          return _c("rs-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _vm.formData.resourceType === 1
                    ? _c("rs-form-item", { attrs: { label: "一级菜单图标" } }, [
                        _c("i", {
                          staticClass: "font_family",
                          domProps: {
                            innerHTML: _vm._s(_vm.formData.iconCode),
                          },
                        }),
                        _vm.formData.iconCode
                          ? _c(
                              "a",
                              {
                                staticClass: "main_font marginLeft15",
                                on: { click: _vm.showIcon },
                              },
                              [_vm._v("更改")]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "main_font",
                                on: { click: _vm.showIcon },
                              },
                              [_vm._v("选择")]
                            ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.jumpBack(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }